import { createSlice } from '@reduxjs/toolkit';
import { fetchUser } from './async-action';
import { clearAllTokens } from '../../helper/token';
import { localStorageActiveRoleKey } from '../../constants/values';
import { getI18n } from 'react-i18next';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    isFetched: false,
  },
  reducers: {
    logoutUser: (state) => {
      clearAllTokens();
      return { isFetched: true, isLoggedIn: false };
    },
    changeUserType: (state, { payload }) => {
      localStorage.setItem(localStorageActiveRoleKey, payload);
      state.userType = payload;
      return state;
    },
    updateUser: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => {
      let userType;
      if (
        action.payload.user_type.includes(
          localStorage.getItem(localStorageActiveRoleKey)
        )
      ) {
        userType = localStorage.getItem(localStorageActiveRoleKey);
      } else {
        localStorage.setItem(localStorageActiveRoleKey, action.payload.user_type[0]);
        userType = action.payload.user_type[0];
      }
      return {
        ...state,
        ...action.payload,
        isFetched: true,
        isLoggedIn: true,
        userType,
        userFullName:
          getI18n().language === 'en'
            ? action.payload.english_name
            : action.payload.arabic_name,
      };
    },
    [fetchUser.rejected]: (state, action) => {
      clearAllTokens();
      return { isFetched: true, isLoggedIn: false };
    },
  },
});

export const { logoutUser, changeUserType, updateUser } = userSlice.actions;

export default userSlice.reducer;
