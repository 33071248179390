import { createSlice, combineReducers } from '@reduxjs/toolkit';

export const invitedInvestorsSlice = createSlice({
  name: 'data',
  initialState: {
    filter: {},
    search: '',
  },
  reducers: {},
});

export const { setFilters, setSearch } = invitedInvestorsSlice.actions;

export default combineReducers({
  [invitedInvestorsSlice.name]: invitedInvestorsSlice.reducer,
});
