import { createContext } from 'react';

// Converts Bytes size to MB
export const bytesToMB = (bytes) =>
  Number((Number(bytes) / (1024 * 1024)).toFixed(1));

// Converts Bytes size to KB
export const bytesToKB = (bytes) => Number((Number(bytes) / 1024).toFixed(1));

// Converts MB size to bytes
export const MBToBytes = (MB) => MB * 1000000;

export const bytesToTextSize = (bytes) =>
  bytesToMB(bytes) !== 0 ? `${bytesToMB(bytes)} MB` : `${bytesToKB(bytes)} KB`;

export const getPropertyPriceChangeColor = (change) =>
  change < 0 ? '#e02626' : change > 0 ? '#69d16e' : 'inherit';

export const CurrencyContext = createContext();

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
