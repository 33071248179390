import { createTheme } from '@material-ui/core';

import {
  MontserratThin,
  MontserratExtraLight,
  MontserratLight,
  MontserratRegular,
  MontserratMedium,
  MontserratSemiBold,
  MontserratBold,
  MontserratExtraBold,
  MontserratBlack,
  ALFontLBCRegular,
  ALFontLBCBold,
} from './fonts';

const fontFaces = [
  MontserratThin,
  MontserratExtraLight,
  MontserratLight,
  MontserratRegular,
  MontserratMedium,
  MontserratSemiBold,
  MontserratBold,
  MontserratExtraBold,
  MontserratBlack,
  ALFontLBCRegular,
  ALFontLBCBold,
];

export const createCustomTheme = (direction) =>
  createTheme({
    spacing: 2,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1380,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: fontFaces.map((f) => f.src).join(','),
      useNextVariants: true,
      allVariants: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontWeight: 400,
      },
      h1: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontWeight: 800,
        fontSize: '2.2rem',
        lineHeight: 1.167,
      },
      h2: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontWeight: 600,
        fontSize: '1.8rem',
        lineHeight: 1.2,
      },
      h3: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontWeight: 600,
        fontSize: '1.4rem',
        lineHeight: 1.167,
      },
      h4: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontWeight: 600,
        fontSize: '1.2rem',
        lineHeight: 1.235,
      },
      h5: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontWeight: 500,
        fontSize: '1.1rem',
        lineHeight: 1.334,
      },
      h6: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontWeight: 500,
        fontSize: '1.05rem',
      },
      body1: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontSize: '1rem',
      },
      body2: {
        fontFamily: 'ALFontLBC, Montserrat',
        fontSize: '0.9rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': fontFaces,
        },
      },
      MuiTableCell: {
        root: {
          padding: '16px 10px',
        },
      },
      MuiTab: {
        root: {
          fontWeight: 600,
        },
      },
      MuiButton: {
        root: {
          fontSize: '0.7rem',
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '0.7rem',
        },
      },
    },
    props: {
      MuiTextField: {
        variant: 'outlined',
      },
    },
    palette: {
      primary: {
        dark: '#008042',
        main: '#008042', // #3cb772
        light: '#85cca7',
        contrastText: '#fff',
      },
      secondary: {
        dark: '#051d39',
        main: '#21376c',
        light: '#528bbf',
        contrastText: '#fff',
      },
      error: {
        main: '#aa3354',
        contrastText: '#fff',
      },
      success: {
        main: '#00FF00',
      },
    },
    direction,
  });
