import { createSlice } from '@reduxjs/toolkit';

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    filter: {},
    search: '',
  },
  reducers: {
    setFilter: (state, { payload }) => {
      state.filter = payload;
      return state;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      return state;
    },
  },
});

export const { setFilter, setSearch } = ordersSlice.actions;

export default ordersSlice.reducer;
