import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';

import App from './App';
import store from './store';
import './utils/i18n';
import './sentry';

import { CurrencyContext } from './helper/utils';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CurrencyContext.Provider value={{ text: 'SAR', symbol: ' SAR' }}>
          <App />
        </CurrencyContext.Provider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
