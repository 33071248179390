import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUser } from '../../store/user/async-action';
import { logoutUser } from '../../store/user';
import { clearAllTokens, getToken, setToken } from '../../helper/token';
import { useRef } from 'react';
import apiManager from '../../utils/apiManager';

import { axiosConfigEvent } from '../../utils/events';

const logoutRequiredRouts = [
  '/confirm-email/',
  '/reset-password/',
  '/onboard-investor/',
];
export default function UserSessionManager(props) {
  const { current } = useRef({
    newAccessTokenPromise: null,
    currentInterceptor: null,
  });
  const { isFetched } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const path = window.location.pathname;

  const setTheDefaultTokenInAxios = () => {
    // This token will be used for all the API calls.
    apiManager.defaults.headers.common['authorization'] = getToken()[0];
  };

  const removeActiveInterceptor = () => {
    apiManager.interceptors.response.eject(current.currentInterceptor);
  };

  const addNewErrorInterceptor = (interceptor) => {
    current.currentInterceptor = apiManager.interceptors.response.use(
      undefined,
      interceptor
    );
  };

  const configureAxios = async () => {
    setTheDefaultTokenInAxios();

    if (current.currentInterceptor !== null) removeActiveInterceptor();

    addNewErrorInterceptor((rootError) => {
      if (rootError.response?.data?.code !== 'token_not_valid') throw rootError;
      if (rootError.config.url.includes('get-new-access-token')) {
        clearAllTokens();
        window.location.href = '/login';
        throw rootError;
      }

      if (!current.newAccessTokenPromise)
        current.newAccessTokenPromise = apiManager
          .post('/api/system-users/get-new-access-token/', {
            refresh: getToken()[1],
          })
          .then((res) => {
            current.newAccessTokenPromise = null;
            setToken(res.data.access);
          });

      return current.newAccessTokenPromise.then(() =>
        apiManager({
          ...rootError.config,
          headers: {
            ...rootError.config.headers,
            authorization: getToken()[0],
          },
        })
      );
    });
  };

  useEffect(() => {
    if (logoutRequiredRouts.includes(path)) {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(logoutUser());
      return;
    }

    const onRefresh = () => configureAxios();
    axiosConfigEvent.addEventListener('refresh-config', onRefresh);

    const [token] = getToken();
    if (token) {
      axiosConfigEvent.refreshConfig();
      dispatch(fetchUser());
    } else {
      dispatch(logoutUser());
    }

    return () => axiosConfigEvent.removeEventListener('refresh-config', onRefresh);
  }, []);

  return isFetched ? props.children : null;
}
