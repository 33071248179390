import { lazy } from 'react';

// Authorization Components
const OnboardInvestor = lazy(() =>
  import('../auth/AdminOnboarding/OnboardInvestor')
);
const Login = lazy(() => import('../auth/Login/Login'));
const KYCOnboardInvestor = lazy(() => import('../auth/KYC/KYCOnboardInvestor'));
const ResetPassword = lazy(() => import('../auth/Login/ResetPassword'));
const VerifyLoginOTP = lazy(() => import('../auth/Login/VerifyOTP'));
const KYCVerifyOTP = lazy(() => import('../auth/KYC/KYCVerifyOTP'));
const ForgotPassword = lazy(() => import('../auth/Login/ForgotPassword'));
const EmailConfirmation = lazy(() => import('../auth/EmailConfirmation'));

export const nonProtectedRoutes = [
  {
    path: '/login',
    component: Login,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/verify-otp',
    component: VerifyLoginOTP,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/verify-registration-otps',
    component: KYCVerifyOTP,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/register',
    component: KYCOnboardInvestor,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/onboard-investor',
    component: OnboardInvestor,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/confirm-email',
    component: EmailConfirmation,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/verify-email',
    component: EmailConfirmation,
    hiddenInSidebar: true,
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    hiddenInSidebar: true,
    exact: true,
  },
];
