import { configureStore } from '@reduxjs/toolkit';

import userReducer from './user';
import usersReducer from './users';
import marketReducer from './market';
import ordersReducer from './orders';
// import tradesReducer from './trades';
// import transfersReducer from './transfers';
import accountReducer from './account';
// import ibdaxWalletReducer from './ibdaxWallet';
import holdingsReducer from './holdings';
import propertyReducer from './property';
import dividendReducer from './dividend';
import feeGroupReducer from './feeGroups';
// import applicationUsersReducer from './applicationUsers';
import invitedInvestorsReducer from './invitedInvestors';
// import marketManagementReducer from './marketManagement';
// import marketAnnouncementsReducer from './marketAnnouncements';
// import propertyAnnouncementsReducer from './propertyAnnouncements';

export default configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    market: marketReducer,
    orders: ordersReducer,
    // trades: tradesReducer,
    // transfers: transfersReducer,
    account: accountReducer,
    // ibdaxWallet: ibdaxWalletReducer,
    holdings: holdingsReducer,
    property: propertyReducer,
    dividends: dividendReducer,
    feeGroups: feeGroupReducer,
    // applicationUsers: applicationUsersReducer,
    invitedInvestors: invitedInvestorsReducer,
    // marketManagement: marketManagementReducer,
    // marketAnnouncements: marketAnnouncementsReducer,c
    // propertyAnnouncements: propertyAnnouncementsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
