import { createSlice } from '@reduxjs/toolkit';

export const ordersSlice = createSlice({
  name: 'holdings',
  initialState: {
    filter: {},
    search: '',
    orderStatusTypes: [],
  },
  reducers: {
    setSearch: (state, { payload }) => {
      state.search = payload;
      return state;
    },
  },
});

export const { setSearch } = ordersSlice.actions;

export default ordersSlice.reducer;
