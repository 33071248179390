import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  basicDetails: {
    property_long_name: '',
    property_long_name_en: '',
    yield_percent: '',
    property_trading_name: '',
    property_trading_name_en: '',
    property_locality_ar: '',
    property_locality: '',
    property_type: '',
    service_type: '',
    property_city: '',
    property_city_ar: '',
    initial_unit_price: '',
    google_maps_address: '',
    property_description: '',
    property_description_en: '',
    pincode: '',
  },
  units: {
    totalUnits: 0,
    ownersList: [],
  },
  files: { propertyImages: [], propertyFiles: [] },
  errors: {},
};

export const createNewPropertySlice = createSlice({
  name: 'createPropertyData',
  initialState: initialState,
  reducers: {
    clearNewPropertyData: () => {
      return initialState;
    },
    setBasicDetails: (state, { payload }) => {
      state.basicDetails[payload.fieldName] = payload.fieldValue;
      return state;
    },
    setUnits: (state, { payload }) => {
      state.units = payload;
      return state;
    },
    setPropertyFiles: (state, { payload }) => {
      state.files.propertyFiles = [...state.files.propertyFiles, payload];
      return state;
    },
    setPropertyImages: (state, { payload }) => {
      state.files.propertyImages = [...state.files.propertyImages, ...payload];
      return state;
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
      return state;
    },
  },
});

export const {
  setBasicDetails,
  setPropertyFiles,
  setPropertyImages,
  setUnits,
  setErrors,
  clearNewPropertyData,
} = createNewPropertySlice.actions;

export default createNewPropertySlice;
