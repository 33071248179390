import _MontserratThin from '../assets/fonts/Montserrat/Montserrat-Thin.ttf';
import _MontserratExtraLight from '../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf';
import _MontserratLight from '../assets/fonts/Montserrat/Montserrat-Light.ttf';
import _MontserratRegular from '../assets/fonts/Montserrat/Montserrat-Regular.ttf';
import _MontserratMedium from '../assets/fonts/Montserrat/Montserrat-Medium.ttf';
import _MontserratSemiBold from '../assets/fonts/Montserrat/Montserrat-SemiBold.ttf';
import _MontserratBold from '../assets/fonts/Montserrat/Montserrat-Bold.ttf';
import _MontserratExtraBold from '../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf';
import _MontserratBlack from '../assets/fonts/Montserrat/Montserrat-Black.ttf';
import _ALFontLBCRegular from '../assets/fonts/ALFont/ALFont-LBC-Regular.otf';
import _ALFontLBCBold from '../assets/fonts/ALFont/ALFont-LBC-Bold.otf';

export const ALFontLBCRegular = {
  fontFamily: 'ALFontLBC',
  src: `url(${_ALFontLBCRegular}) format('woff2')`,
  fontWeight: 400,
};
export const ALFontLBCBold = {
  fontFamily: 'ALFontLBC',
  src: `url(${_ALFontLBCBold}) format('woff2')`,
  fontWeight: 700,
};

export const MontserratThin = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratThin}) format('woff2')`,
  fontWeight: 100,
};
export const MontserratExtraLight = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratExtraLight}) format('woff2')`,
  fontWeight: 200,
};
export const MontserratLight = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratLight}) format('woff2')`,
  fontWeight: 300,
};
export const MontserratRegular = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratRegular}) format('woff2')`,
  fontWeight: 400,
};
export const MontserratMedium = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratMedium}) format('woff2')`,
  fontWeight: 500,
};
export const MontserratSemiBold = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratSemiBold}) format('woff2')`,
  fontWeight: 600,
};
export const MontserratBold = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratBold}) format('woff2')`,
  fontWeight: 700,
};
export const MontserratExtraBold = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratExtraBold}) format('woff2')`,
  fontWeight: 800,
};
export const MontserratBlack = {
  fontFamily: 'Montserrat',
  src: `url(${_MontserratBlack}) format('woff2')`,
  fontWeight: 900,
};
