import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { create } from 'react-modal-promise';
import { useTranslation } from 'react-i18next';

import { ConfirmTextFieldError } from '../ConfirmTextFieldError';

const ConfirmationDialog = ({
  isOpen,
  onResolve,
  onReject,
  description,
  title,
  confirmationMessage,
  confirmationWord,
  inputs = [],
}) => {
  const [word, setWord] = useState('');
  const [wordError, setWordError] = useState(null);
  const { t, i18n } = useTranslation();
  const [inputsArray, setInputsArray] = useState(
    inputs.map((i) => ({ name: i.name, value: i.defaultValue })) // input name must be unique
  );
  const submit = () => onResolve(inputs ? inputsArray : 'resolved');
  const reject = () => onReject('rejected');

  const dialogStyle = {
    backgroundColor: confirmationMessage || inputs ? '#fff' : '#646464',
    color: confirmationMessage || inputs ? '#000' : 'white',
    overflowY: 'clip',
  };

  const onConfirm = () => {
    if (word !== confirmationWord) {
      setWordError(confirmationWord);
      return;
    }
    setWordError(null);
    submit();
  };

  const onChangeWord = (e) => {
    if (e.target.value === confirmationWord) setWordError(null);
    setWord(e.target.value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={reject}
      maxWidth="sm"
      fullWidth
      dir={i18n.language === 'ar' ? 'rtl' : undefined}
    >
      <DialogTitle style={dialogStyle}>{title}</DialogTitle>
      <DialogContent style={dialogStyle}>
        {inputs?.map((i) => {
          if (i.type === 'checkbox') {
            const currentState = inputsArray.find(
              (inputObj) => inputObj.name === i.name
            );
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentState.value}
                    onChange={() =>
                      setInputsArray(
                        inputsArray.map((inputObj) =>
                          inputObj.name === i.name
                            ? { ...inputObj, value: !inputObj.value }
                            : inputObj
                        )
                      )
                    }
                    name="checkbox"
                  />
                }
                label={i.label}
              />
            );
          }
          if (['text', 'number'].includes(i.type)) {
            const currentState = inputsArray.find(
              (inputObj) => inputObj.name === i.name
            );
            return (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id={i.name}
                label={i.label}
                name={i.name}
                type={i.type}
                autoComplete={i.name}
                value={currentState.value}
                onChange={(e) =>
                  setInputsArray(
                    inputsArray.map((inputObj) =>
                      inputObj.name === i.name
                        ? { ...inputObj, value: e.target.value }
                        : inputObj
                    )
                  )
                }
                autoFocus
                error={Boolean(i.error)}
                helperText={i.error}
              />
            );
          }
          return null;
        })}
        {confirmationMessage && (
          <TextField
            error={!!wordError}
            helperText={
              <ConfirmTextFieldError confirmationText={confirmationWord} />
            }
            label={confirmationMessage}
            value={word}
            onChange={onChangeWord}
            fullWidth
          />
        )}
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions style={dialogStyle}>
        <Button onClick={reject} variant="outlined" style={{ marginRight: 12 }}>
          {t('components.confirmation_dialog.cancel')}
        </Button>
        {confirmationMessage ? (
          <Button variant="outlined" onClick={onConfirm} id="cancel">
            {t('components.confirmation_dialog.confirm')}
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={submit} id="continue">
            {t('components.confirmation_dialog.continue')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const showConfirmDialog = create(ConfirmationDialog);
