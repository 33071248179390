import { createSlice } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    walletAccount: {},
    IbanAccount: {},
  },
  reducers: {
    setIBANDetails: (state, { payload }) => {
      state.IbanAccount = payload;
      return state;
    },
    resetWalletInformation: (state) => {
      state.walletAccount = {
        ...state.walletAccount,
        total_balance: null,
        available_balance: null,
        blocked: null,
        dividend_earned: null,
        dividend_paid: null,
      };
      return state;
    },
    updateWalletInformation: (state, { payload }) => {
      state.walletAccount = {
        ...state.walletAccount,
        ...payload,
      };
      return state;
    },
  },
  extraReducers: {},
});

export const { setIBANDetails, updateWalletInformation, resetWalletInformation } =
  accountSlice.actions;

export default accountSlice.reducer;
