import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'dividends',
  initialState: {
    search: '',
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      return state;
    },
  },
});

export const { setSearch } = userSlice.actions;

export default userSlice.reducer;
