class AxiosConfigEvent extends EventTarget {
  refreshConfig() {
    this.dispatchEvent(new Event('refresh-config'));
  }
}
export const axiosConfigEvent = new AxiosConfigEvent();

class CreatePropertyEvent extends EventTarget {
  clearFrom() {
    this.dispatchEvent(new Event('clear-form'));
  }
}
export const propertyEvent = new CreatePropertyEvent();
