import { makeStyles } from '@material-ui/core';

export const useSidebarStyles = makeStyles((theme) => {
  return {
    drawerPaper: {
      whiteSpace: 'nowrap',
      paddingTop: theme.spacing(4),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: theme.palette.primary.main + ' !important',
      boxShadow: '#0000002e 3px 0 8px 0',
      zIndex: 100,
    },
    scroll: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    logo: {
      marginTop: '10%',
      marginBottom: '10%',
      alignSelf: 'center',
      borderRadius: '50%',
      padding: '8% 0',
      background: 'white',
      maxWidth: '50%',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '50%',
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      minWidth: 38,
      color: theme.palette.common.white,
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      width: theme.spacing(8),
    },
    btnlink: {
      textDecoration: 'none',
    },
    submenu: {
      marginLeft: 16,
    },
    nested: {
      paddingLeft: theme.spacing(6),
    },
    my2: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: 'red !important',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
      },
    },
    font: {
      color: theme.palette.common.white + ' !important',
      textTransform: 'uppercase',
      fontWeight: 600,
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
      },
    },
    dNone: {
      opacity: 0,
    },
    copyright: {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
      paddingBottom: '3em',
    },
    copyrightText: {
      borderTop: '1px solid #ccc',
      display: 'inline',
      padding: '4px 10px',
      color: theme.palette.common.white,
    },
  };
});
