import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import englishTranslation from '../locales/en-US.json';
import arabicTranslation from '../locales/ar-SA.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: englishTranslation,
    },
    ar: {
      translation: arabicTranslation,
    },
  },
  lng: localStorage.getItem('language') || 'ar',
  fallbackLng: 'ar',

  interpolation: {
    escapeValue: false,
  },
});
